import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { API_URL } from 'src/config';
import { CookieService } from '../core/backend-adapter/cookie.service';
import { DBService } from '../core/backend-adapter/db.service';
import RZLib from '../core/backend-adapter/db2/RZ-lib.helpers';
import { NotifyService } from '../core/layouts/notifications/notify/notify.service';
import { IReviewData } from './review-management.interfaces';
import Papa from 'papaparse';
import { Observable, from } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SocketService } from '../core/backend-adapter/socket.service';
import { filter, map, tap } from 'rxjs/operators';
import { generateJWTToken } from '../core/helpers/jwt-token.utils';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  downloadType = 'csv';
  downloadTypes = [
    { value: 'csv', label: 'CSV' },
    { value: 'xls', label: 'XLS' },
    { value: 'xlsx', label: 'XLSX' },
  ];

  constructor(
    private dbService: DBService,
    private http: HttpClient,
    private notify: NotifyService,
    private cookieService: CookieService,
    private socketService: SocketService
  ) {}

  updateReviewResponse(review): void {
    const saveObj = { ...review, replyUpdatedAt: new RZLib.Timestamp().setNow() };

    this.dbService.ReviewGMB.saveObject(saveObj).then(
      (resp) => resp,
      (err) => {
        console.log('Error saving response', err);
      }
    );
  }

  bulkUpdateReviewsResponse(reviews: IReviewData[]): void {
    reviews.forEach((review) => {
      const saveObj = { ...review, replyUpdatedAt: new RZLib.Timestamp().setNow() };

      this.dbService.ReviewGMB.saveObject(saveObj).then(
        (resp) => resp,
        (err) => {
          console.log('Error saving response', err);
        }
      );
    });
  }

  deleteReviewResponse(review): void {
    const saveObj = { ...review, replyComment: null, replyUpdatedAt: null };
    this.dbService.ReviewGMB.saveObject(saveObj).then(
      (resp) => resp,
      (err) => {
        console.log('Error deleting response', err);
      }
    );
  }

  /**
   * @description Function to delete a review.
   * @param review: Gets a review.
   * @returns Observable<any>
   */
  public deleteReview(review): Observable<any> {
    const saveObj = { ...review, _status: 'D' };
    return from(this.socketService.sendRequest('delete-review', { item: { _id: saveObj._id } })).pipe(
      filter(
        (res) => {
          if (res) return true;
          else return false;
        },
        (err) => {
          return false;
        }
      )
    );
  }

  public exportAllReviews(): void {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/recent_reviews`;
    const query = `?format=${this.downloadType}`;

    this.http.get(`${url}${query}`, options).subscribe(
      (result) => {
        // Convert CSV data to JSON.
        let JsonResp = this.csvToJson(result);
        let dataLength: number = JsonResp && JsonResp?.data && JsonResp?.data?.length;

        switch (dataLength) {
          case 0:
            // Generate empty report when there's no data...
            const noData = JsonResp['data'];
            this.genCSVReport(noData, this.downloadType);
            break;
          case 1:
            // When only header`s present.
            JsonResp.data[0][15] = `Directories`;
            const headerData = JsonResp['data'];
            this.genCSVReport(headerData, this.downloadType);
            break;
          default:
            const mappedData = this.mapDataForExport(JsonResp);
            const data = mappedData['data'];
            this.genCSVReport(data, this.downloadType);
            break;
        }
      },
      (error) => {
        this.notify.error('Error while exporting');
      }
    );
  }

  private mapDataForExport(JsonResp): any {
    for (let i = 0; i < JsonResp?.data?.length; i++) {
      JsonResp.data[0][15] = 'Directories';
      switch (JsonResp.data[i][15]) {
        case 'gmb':
          JsonResp.data[i][15] = 'Google';
          break;
        case 'vitals.com-lde':
          JsonResp.data[i][15] = 'Vitals';
          break;
        case 'zocdoc.com-lde':
          JsonResp.data[i][15] = 'ZocDoc';
          break;
        case 'tripadvisor.com-lde':
          JsonResp.data[i][15] = 'Tripadvisor';
          break;
        case 'healthgrades.com-lde':
          JsonResp.data[i][15] = 'Healthgrades';
          break;
        case 'yelp-yelp':
          JsonResp.data[i][15] = 'Yelp';
          JsonResp.data[i][10] = `Yelp Review text only available in console.`;
          break;
      }
    }
    return JsonResp;
  }

  public genCSVReport(data, fileType: string, fileName?: string): void {
    //Convert JSON to CSV.
    const unparseJsonData = this.jsonToCsv(data);

    // Generate CSV file.
    const blob = new Blob([unparseJsonData], {
      type: 'text/plain;charset=utf-8',
    });
    // set dynamic file name.
    if (fileName) {
      saveAs(blob, `${fileName}.${fileType}`);
    } else {
      saveAs(blob, `exported-reviews.${fileType}`);
    }
  }

  private csvToJson(csvData: any): any {
    return Papa?.parse(csvData);
  }

  public jsonToCsv(jsonData: any): any {
    return Papa?.unparse(jsonData);
  }

  // Needs to be refactored, beign used for Reports tools feature.
  public exportReviews(params): void {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/${params}`;

    this.http.get(`${url}`, options).subscribe(
      (result) => {
        // Convert CSV data to JSON.
        let JsonResp = this.csvToJson(result);
        const data = JsonResp['data'];
        this.genCSVReport(data, this.downloadType);
      },
      (error) => {
        this.notify.error('Error while exporting');
      }
    );
  }

  public getExportedReport(
    accountId: any,
    userId: any,
    directoryName: string,
    reportType: string,
    timeunit: string,
    periods: any,
    storecode?: any
  ): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `${jwtToken}`,
      }),
      responseType: 'text' as any,
    };

    let url: string;
    if(environment.name === 'local' || environment.name === 'development') {
      url = `https://dev-io.renderseo.com/insights/${directoryName}/${reportType}?timeunit=${timeunit}&periods=${periods}`;
    } else {
      url = `https://io.renderseo.com/insights/${directoryName}/${reportType}?timeunit=${timeunit}&periods=${periods}`;
    }

    if(reportType === 'account' && timeunit === 'month' && (periods === 3 || periods === 6 || periods === 12 || periods === 24)) {
      // Adding maps=true query param for account report with 3 months period to include current month as well.
      url = `${url}&maps=true`;
    }

    if (storecode?.storeCode) {
      url = `${url}&storecode=${storecode['storeCode']}`;
    }

    return this.http.get(url, options)
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
  }

  public exportReviewsNew(params, fileFormat: string): void {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/reviewscontent_by_review${params}`;

    this.http.get(`${url}`, options).subscribe((res) => {
      let JsonResp = this.csvToJson(res);
      const data = JsonResp['data'];
      if (fileFormat === 'csv') {
        this.genCSVReport(data, 'csv');
      } else {
        this.exportAsExcelFile(data, 'exported-reviews');
      }
    });
  }


  public generateReport(
    accountId: any,
    userId: any,
    directoryName: string,
    reportType: string,
    timeunit: string,
    periods: any
  ): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);

    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `${jwtToken}`,
      }),
      responseType: 'text' as any,
    };

    const url: string = `https://io.renderseo.com/insights/${directoryName}/${reportType}?timeunit=${timeunit}&periods=${periods}`;
    return this.http.get(url, options)
    .pipe(
      map((res) => this.csvToJson(res))
    );
  }

  public generateReviewsReport(params): Observable<any> {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/${params}`;

    return this.http.get(`${url}`, options);
  }

}
