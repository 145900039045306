import { Component, OnDestroy, OnInit } from '@angular/core';
import { GraphConfigService } from '../shared/services/graph-config.service';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { AppleMapsInsightsDataService } from '../shared/services/apple-maps-insights-data.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterButtonComponent } from '../shared/components/filter-buttons/filter-buttons.component';
import { Observable, Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { AppleMapsSpatialInsightsService } from '../shared/services/apple-maps-spatial-insights.service';
import { SpatialData } from '../shared/models/spatial-data-model';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { ReviewsService } from 'src/app/review-management/reviews.service';
import { ConvertCSVToJSONService } from '../shared/services/convert-csv-to-json.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { AdvanceFiltersService } from '../shared/services/advance-filters.service';

@Component({
  selector: 'app-apple-dashboards-maps-tab',
  templateUrl: './apple-dashboard-tab.component.html',
  styleUrls: ['./apple-dashboard-tab.component.scss'],
})
export class AppAppleDashboardMapsTabComponent implements OnInit, OnDestroy {
  private previousSelectedInsights: string[] = []; // unsued
  private ngUnsubscribe$ = new Subject();
  private allAdvanceFilters: any[] = [];
  private operandType: string = '-or';
  private accountId: number;
  private userId: number;
  private userLoginId: number;

  public readonly CardDescription: string = 'Where people were when they got directions to your location.';
  public readonly cardTitle1: string = 'Search clicks Location';
  public readonly cardTitle2: string = 'Directions Location';
  public readonly filterButtonTitle: string = 'Filter by';
  public tags = [
    {
      label: 'Store code filter',
      disabled: false,
      command: () => {
        this.primaryFilters();
      },
    },
    // {
    //   label: 'Advanced filters',
    //   disabled: false,
    //   command: () => {
    //     this.advanceFilters();
    //   },
    // },
  ];
  public selectedRange: number = 3;
  public dateRanges;
  public insightsGraphConfig: unknown;
  public data: unknown;
  public showReviews: boolean;
  public demoData = [];
  public displayInsightsPopup: boolean = false;
  public selectedInsightPrefs: string[] = ['searchCard', 'views', 'directions', 'websiteClicks', 'calls', 'shares'];
  public popupData = [];
  public searchLocationsZipCodes: SpatialData[] = [];
  public directionsLocationsZipCodes: SpatialData[] = [];
  public date: Date = new Date();
  public componentRef: DynamicDialogRef | undefined;
  public displayCardInsights: boolean = true;
  public displaySpatialInsights: boolean = false;
  public locations: any[] = [];
  public selectedLocation: any;
  public whereClauss: any[] = [];
  public fullWidth: boolean = false;

  constructor(
    private graphConfigService: GraphConfigService,
    private appleMapsInsightsConfigService: MapsInsightsConfigService,
    private appleMapsInsightsDataService: AppleMapsInsightsDataService,
    private dialogService: DialogService,
    private socketService: SocketService,
    private appleMapsSpatialInsightsService: AppleMapsSpatialInsightsService,
    public sessionService: SessionService,
    private reviewsService: ReviewsService,
    private convertCSVToJSONService: ConvertCSVToJSONService,
    private notifyService: NotifyService,
    private advanceFiltersService: AdvanceFiltersService
  ) {}

  ngOnInit(): void {
    this.sessionService.getCurrentUser$().subscribe((user) => {
      this.userLoginId = user?.login?._id;
    });

    this.sessionService.getSelectedAccount$().subscribe((account) => {
      this.accountId = account?._id || null;
    });

    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();

    this.dateRanges = this.graphConfigService.dateRanges;
    this.insightsGraphConfig = this.appleMapsInsightsConfigService.stackOptions;
    this.showReviews = this.appleMapsInsightsDataService.showReviews;
    this.popupData = this.graphConfigService.applePopupData;
    this.getFiltersOperandStatus();
    this.getAdvanceFilters();
    this.getAllLocations();
    this.getMetricsDataOnFilterUpdates();
  }

  public dateRangeChanged(event): void {
    const { timeunit, periods } = event.value;
    this.fullWidth = periods === 12 || periods === 24 ? true : false;
    this.getInsightsData(event.value);
  }

  public editInsights(): void {
    this.previousSelectedInsights = [...this.selectedInsightPrefs];
    this.displayInsightsPopup = true;
  }

  public closeInsightsPopup(): void {
    this.setInsightsCardVisibility();
    this.displayInsightsPopup = false;
  }

  public prefsChanged(): void {
    this.setInsightsCardVisibility();
  }

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  }

  public saveInsightsPrefs(): void {
    this.displayInsightsPopup = false;
  }

  public primaryFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Filter by Store Code',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.appleMapsInsightsDataService.setBasicFiltersData()],
        isAdvanceFilterActive: false,
      },
    });
    let storeCode: string = '';
    this.componentRef.onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      const storeCodeObj = res[0];
      const label = res[0]['label'];
      storeCode = storeCodeObj['value'] && storeCodeObj['value'].length ? storeCodeObj['value'].join(',') : '';
      const filters = {
        label,
        storeCode,
      };
      this.getInsightsData(this.selectedRange, filters);
    });
  }

  public advanceFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Advance filters',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.allAdvanceFilters],
        isAdvanceFilterActive: true,
      },
    });

    this.appleMapsInsightsDataService.getDialogRef(this.componentRef);
  }

  private checkInsightsCardsVisibility(): void {
    this.demoData.forEach((item) => {
      item['visibility'] = this.selectedInsightPrefs.includes(item.id) ? true : false;
      return item;
    });
  }

  /**
   * @description Function to switch between Card insights and Spatial insights view.
   * @returns void
   */
  public openTab(): void {
    this.displayCardInsights = !this.displayCardInsights;
    this.displaySpatialInsights = !this.displaySpatialInsights;

    // Get Spatial insights data is Spatial Insights tab is active.
    if (this.displaySpatialInsights) {
      this.initSpatialDataCall();
    }
  }

  /**
   * @description To get lits of advances filters based on user's authorization.
   */
  private getAdvanceFilters(): void {
    this.advanceFiltersService.getAdvanceFiltersLists(this.accountId, this.userId).subscribe((res) => {
      this.allAdvanceFilters = this.appleMapsInsightsDataService.setAdvanceFiltersData().map((el) => {
        return {
          ...el,
          checkboxList:
            res[el.id] && res[el.id]?.length
              ? res[el.id].map((item) => {
                  return {
                    label: item,
                  };
                })
              : [],
        };
      });
    });
  }

  getInsightsData(range, filters?: any): void {
    const { timeunit = 'month', periods = 3 } = range;
    const config = {
      header: true,
    };

    this.reviewsService
      .getExportedReport(this.accountId, this.userId, 'apple', 'account', timeunit, periods, filters)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          const currData = this.convertCSVToJSONService
            .csvToJson(res, config)
            ['data'].filter((data) => Object.keys(data).length > 1);

          const {
            schlocationtap,
            pcview,
            pctapdirection,
            pctapwebsite,
            pctapcall,
            pctapshare,
            pctapshowcase,
            pcviewmenu,
            pctaporder,
            pcorderdelivery,
            pcorderfood,
            pcordertakeout,
            pcpickup,
            pcreserveparking,
            pcreservetable,
            pcjoinwaitlist,
            pcscheduleappt,
            labels,
          } = this.appleMapsInsightsDataService.mapInsightsData(currData, periods);

          this.demoData = [
            {
              id: 'searchCard',
              title: 'Search Clicks Total',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Search clicks',
                    backgroundColor: '#791e6c',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: schlocationtap,
                  },
                  // {
                  //   type: 'bar',
                  //   label: 'Category search',
                  //   backgroundColor: '#d51ba0',
                  //   barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                  //   maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                  //   data: schlocationtapcategory,
                  // },
                  // {
                  //   type: 'bar',
                  //   label: 'Other',
                  //   backgroundColor: '#fa6bc1',
                  //   barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                  //   maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                  //   data: schlocationtapother,
                  // },
                ],
              },
            },
            {
              id: 'views',
              title: 'Views',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Views',
                    backgroundColor: '#491d8b',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcview,
                  },
                ],
              },
            },
            {
              id: 'directions',
              title: 'Directions',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Directions',
                    backgroundColor: '#F68A8A',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctapdirection,
                  },
                ],
              },
            },
            {
              id: 'websiteClicks',
              title: 'Website clicks',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Website clicks',
                    backgroundColor: '#e8e52e',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctapwebsite,
                  },
                ],
              },
            },
            {
              id: 'calls',
              title: 'Calls',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Calls',
                    backgroundColor: '#6e44ad',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctapcall,
                  },
                ],
              },
            },
            {
              id: 'shares',
              title: 'Shares',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Shares',
                    backgroundColor: '#8a3ffc',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctapshare,
                  },
                ],
              },
            },
            {
              id: 'showCaseClicks',
              title: 'Showcase Clicks',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Show case',
                    backgroundColor: '#651e7d',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctapshowcase,
                  },
                ],
              },
            },
            {
              id: 'appointments',
              title: 'Appointments',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Appointments',
                    backgroundColor: '#cf44be',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcscheduleappt,
                  },
                ],
              },
            },
            {
              id: 'menuCard',
              title: 'Menu',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Menu',
                    backgroundColor: '#d1548c',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcviewmenu,
                  },
                ],
              },
            },
            {
              id: 'ordering',
              title: 'Ordering',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Order',
                    backgroundColor: '#206A02',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pctaporder,
                  },
                  {
                    type: 'bar',
                    label: 'Delivery',
                    backgroundColor: '#609c51',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcorderdelivery,
                  },
                  {
                    type: 'bar',
                    label: 'Order food',
                    backgroundColor: '#266317',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcorderfood,
                  },
                  {
                    type: 'bar',
                    label: 'Order takeout',
                    backgroundColor: '#64f241',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcordertakeout,
                  },
                  {
                    type: 'bar',
                    label: 'Order pickup',
                    backgroundColor: '#317d1e',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcpickup,
                  },
                ],
              },
            },
            {
              id: 'reservations',
              title: 'Reservations',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Reserve parking',
                    backgroundColor: '#abdadb',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcreserveparking,
                  },
                  {
                    type: 'bar',
                    label: 'Reserve table',
                    backgroundColor: '#4b797a',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcreservetable,
                  },
                  {
                    type: 'bar',
                    label: 'Join waitlist',
                    backgroundColor: '#1cc6c9',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: pcjoinwaitlist,
                  },
                ],
              },
            },
          ];

          this.checkInsightsCardsVisibility();
        },
        (err) => {}
      );
  }

  /**
   * @description Function runs when date is changed.
   * @returns void
   */
  public changeMonth(): void {
    this.initSpatialDataCall();
  }

  /**
   * @description Function runs when location is changed.
   * @returns void
   */
  public changeLocation(): void {
    this.initSpatialDataCall();
  }

  /**
   * @description Returns the list of locations connected to the owner.
   * @returns void
   */
  private getAllLocations(): void {
    const locations$: Observable<unknown> = from(
      this.socketService.sendRequest('get-locations-summary', {
        columns: ['_id', 'storeCode', 'businessName', 'city', 'state', 'addressLines'],
        where: ['-and', '_parent_id is null'],
      })
    );
    locations$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.locations =
        res['collection']?.length &&
        res['collection'].map((loc) => {
          return {
            id: loc[0],
            storeCode: loc[1],
            businessName: `${loc[2]} - ${loc[1]} - ${loc[3]}, ${loc[4]} - ${loc[5]?.join(', ')}`,
            city: loc[3],
            state: loc[4],
            addressLines: loc[5]?.join(', '),
          };
        });
      this.selectedLocation = this.locations[0];
    });
  }

  public getMetricsDataOnFilterUpdates(): void {
    // this.appleMapsInsightsDataService.currentUpdatedFilterState
    //   .pipe(takeUntil(this.ngUnsubscribe$))
    //   .subscribe(res => {
    //     if (res) {
    //       this.whereClauss = this.appleMapsInsightsDataService.buildWhereClaus(res);
    //       this.getInsightsData(this.selectedRange);
    //     }
    //   });

    this.getInsightsData(this.selectedRange);
  }

  private getFiltersOperandStatus(): void {
    this.appleMapsInsightsDataService.currentFilterOperandType
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((openationType: string) => {
        this.operandType = openationType;
      });
  }

  /**
   * @description Gets Spatial insights data for selected year, month and location.
   * @param userId - number
   * @param accountId - number
   * @param year - number
   * @param month - number
   * @param location_id - number
   * @returns void
   */
  private getSpatialInsightsData(year: number, month: number, location_id: number): void {
    this.appleMapsSpatialInsightsService
      .getSpatialReport(this.userLoginId, this.accountId, location_id, month, year)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          const { DIRECTIONS_LOCATION: directionLocations = [], SEARCH_CLICKS_LOCATION: searchClickLocations = [] } =
            res;

          searchClickLocations &&
            searchClickLocations.length &&
            searchClickLocations.forEach((loc) => {
              const { label, value } = loc;
              this.searchLocationsZipCodes.push({
                zipcode: label.split(',')[0] || 'N/A',
                city: label.split(',')[1] || 'N/A',
                state: label.split(',')[2] || 'N/A',
                score: value || 'N/A',
              });
            });

          directionLocations &&
            directionLocations.length &&
            directionLocations.forEach((loc) => {
              const { label, value } = loc;
              this.directionsLocationsZipCodes.push({
                zipcode: label.split(',')[0] || 'N/A',
                city: label.split(',')[1] || 'N/A',
                state: label.split(',')[2] || 'N/A',
                score: value || 'N/A',
              });
            });
        },
        (err) => {
          this.searchLocationsZipCodes = [];
          this.directionsLocationsZipCodes = [];
        }
      );
  }

  /**
   * @description To set params for Spatial insights endpoint.
   * @param none
   * @returns void
   */
  private initSpatialDataCall(): void {
    const year = this.date.getFullYear();
    const month = this.date.getMonth() + 1;
    const location_id = this.selectedLocation?.id;
    this.getSpatialInsightsData(year, month, location_id);
  }

  /**
   * @description: Get the current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.accountId = account?._id;
      });
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userId = user?.login?._id;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
